import { format, parseISO, formatISO, addHours } from 'date-fns';

/**
 * Converts a UTC ISO string to a local date object
 * @param {string} isoString - ISO string in UTC format from the backend
 * @returns {Date} - Date in local timezone
 */
export const utcToLocalDate = (isoString) => {
    if (!isoString) return null;

    // When you parse an ISO string, JavaScript automatically converts to local time
    return parseISO(isoString);
};

/**
 * Converts a local date to UTC ISO format for sending to the backend
 * @param {Date} localDate - Local date object
 * @returns {string} - ISO string in UTC format
 */
export const localDateToUtc = (localDate) => {
    if (!localDate) return null;

    // toISOString() automatically converts to UTC
    return localDate.toISOString();
};

/**
 * Formats a date for display in the user's locale
 * @param {Date|string} date - Date to format
 * @param {string} formatString - date-fns format string, defaults to 'PPpp'
 * @returns {string} - Formatted date string
 */
export const formatDate = (date, formatString = 'PPpp') => {
    if (!date) return '';

    const dateObj = typeof date === 'string' ? parseISO(date) : date;
    return format(dateObj, formatString);
};

/**
 * Formats a time from a date object in HH:mm format
 * @param {Date|string} date - Date object or ISO string
 * @returns {string} - Time string in HH:mm format
 */
export const formatTimeFromDate = (date) => {
    if (!date) return '';

    const dateObj = typeof date === 'string' ? parseISO(date) : date;
    return format(dateObj, 'HH:mm');
};

/**
 * Calculates end time based on start time and duration
 * @param {Date} startTime - Start time
 * @param {number} durationHours - Duration in hours
 * @returns {Date} - End time
 */
export const calculateEndTime = (startTime, durationHours) => {
    if (!startTime || !durationHours) return null;
    return addHours(startTime, Number(durationHours));
};

export const formatDateTime = (date, formatString = 'PPpp') => {
    if (!date) return '';

    const dateObj = typeof date === 'string' ? parseISO(date) : date;
    return format(dateObj, formatString);
}

export const formatDateWithTimezone = (date) => {
    if (!date) return '';

    const dateObj = typeof date === 'string' ? parseISO(date) : date;
    const timezoneOffset = new Date().getTimezoneOffset() * 60000; // Convert minutes to milliseconds
    const localDate = new Date(dateObj.getTime() - timezoneOffset);
    return format(localDate, 'PPp ');
}